<template>
  <div class="flex flex-col flex-grow h-full">
    <h6 class="mb-10">NIN Verification Required</h6>

    <p class="mb-10">
      Enter the verification code sent to the phone number attached to your nin.
    </p>

    <form @submit.prevent="submit" class="flex flex-col flex-grow">
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-6">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <input-split
        class="mb-16"
        v-model="form.data.otp.value"
      />
      <div
        v-if="getFirstError('otp')"
        class="form-group-2-text mb-10 -mt-12 text-red-500"
      >
        {{ getFirstError('otp') }}
      </div>

      <p class="text-center mb-40">
        Didn’t get code?
        <a
          href="#!"
          class="font-bold text-blue-500 underline"
          :disabled="form.loading"
          @click="resendOtp"
        >
          Resend
        </a>
      </p>

      <div class="flex gap-4 mt-auto">
        <button
          type="submit"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading"
        >
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Proceed</span>
          <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Form2OtpVerification',
  props: {
    nin: {
      type: [String],
      required: true,
    },
    otpVerificationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        'nin',
        'otp',
        'otp_verification_id',
      ], {
        otpResent: false
      }),
    };
  },
  computed: {
    otpValue() {
      return this.form.data.otp.value;
    },
  },
  watch: {
    otpValue(value) {
      if (value.length === 6) {
        this.submit();
      }
    },
    otpVerificationId() {
      this.mapData();
    }
  },
  mounted() {
    this.mapData();
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    mapData() {
      this.form.data.nin.value = this.nin;
      this.form.data.otp_verification_id.value = this.otpVerificationId;
    },
    async resendOtp() {
      this.form.error = null;
      this.form.setLoading();

      await this.sendRequest('personal.onboarding.nin.requestOtp', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user, profile, otp_verification_id } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              const nin = this.getFormValue('nin');
              const data = { otp_verification_id, nin };
              this.$emit('resend-otp', data);
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.setLoading(false);
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.nin.verifyOtp', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user, profile } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              this.$emit('verify-otp');
            });
        },
        error: (error) => {
          const { response: { status } = {} } = error;
          this.form.error = status !== 422 ? error : null;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.setLoading(false);
    },
  },
}
</script>
